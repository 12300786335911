<template>
	<div id="aqt_image_search_button">
        <div class="titlefont aqt_ce_ve search" @click="showPopup('open')" >
<!--			 <img src="../assets/img/search.png" alt="">-->
          <i style="font-size: 20px" class="iconfont iconshaixuan"></i>
        </div>
        <div class="aqt_mnpopup aqt_no_su" v-if="mnPopupIs">
            <div class="aqt_mnpopup_div" v-if="!['/Resourcevideo','/AuthorListNew'].includes($route.path)" >
                <div class="aqt_mnpopup_form">
                    <div class="aqt_cont aqt_su" :style="{height: ['/Resourcevideo','/AuthorListNew'].includes($route.path)?'calc(100% - 9rem)':'calc(100% - 5rem)'}">
                        <div class="aqt_input titlefont aqt_lang_mn">
                            <textarea v-model.trim="condition.name" :placeholder="U2M('ᠡᠬᠢ ᠰᠤᠷᠪᠤᠯᠵᠢ ᠪᠠ ᠨᠣᠮ ᠦᠨ ᠨᠡᠷ᠎ᠡ')"></textarea>
                        </div>
                        <div class="aqt_input titlefont aqt_lang_mn">
                            <textarea v-model.trim="condition.isbn" :placeholder="'ISBN'"></textarea>
                        </div>
                        <div class="aqt_input titlefont aqt_lang_mn">
                            <textarea v-model.trim="condition.author" :placeholder="U2M('ᠵᠣᠬᠢᠶᠠᠯᠴᠢ')"></textarea>
                        </div>
                        <div class="aqt_input titlefont aqt_lang_mn">
                            <textarea @click="selectPopup('open','category')" v-model="condition.category_name" readonly :placeholder="U2M('ᠨᠣᠮ ᠤᠨ ᠲᠥᠷᠥᠯ')"></textarea>
                        </div>
                        <div class="aqt_input titlefont aqt_lang_mn">
                            <textarea @click="selectPopup('open','culture')" v-model="condition.culture_name" readonly :placeholder="U2M('ᠰᠣᠶᠣᠯ ᠤᠨ ᠲᠥᠷᠥᠯ')"></textarea>
                        </div>
                        <div class="aqt_input titlefont aqt_lang_mn">
                            <textarea @click="selectPopup('open','press')" v-model="condition.press_name" readonly :placeholder="U2M('ᠬᠡᠪᠯᠡᠯ ᠦᠨ ᠬᠣᠷᠢᠶᠠᠨ ᠦ ᠨᠡᠷ᠎ᠡ')"></textarea>
                        </div>
                        <div class="aqt_input aqt_lang_mn">
                            <textarea class="aqt_time titlefont" @click="showStartTimePicker" v-model="condition.start_time" readonly :placeholder="U2M('ᠬᠡᠪᠯᠡᠯ ᠦᠨ ᠴᠠᠭ - ᠡᠬᠢᠯᠡᠯ')"></textarea>
                            <textarea  class="aqt_time titlefont" @click="showEndTimePicker" v-model="condition.end_time" style="margin-top: 4%;" readonly :placeholder="U2M('ᠬᠡᠪᠯᠡᠯ ᠦᠨ ᠴᠠᠭ - ᠲᠡᠭᠦᠰᠬᠡᠯ')"></textarea>
                        </div>
                    </div>
                    <div class="aqt_footer">
                        <cube-button class="delete" icon="cubeic-wrong" @click="showPopup('close')"></cube-button><span>&nbsp;</span>
                        <cube-button icon='icon_zhongzhi-copy iconfont'   @click="mnpopupPopupDel"></cube-button><span>&nbsp;</span>
                        <cube-button class="but" @click="mnpopupPopupOk" :light="true" icon="cubeic-search"></cube-button>
                    </div>
                </div>
            </div>
            <div class="aqt_mnpopup_div" v-if="['/Resourcevideo'].includes($route.path)" >
                <div class="aqt_mnpopup_form">
                    <div class="aqt_cont aqt_su" :style="{height: ['/Resourcevideo','/AuthorListNew'].includes($route.path)?'calc(100% - 9rem)':'calc(100% - 5rem)'}">
                        <div class="aqt_input titlefont aqt_lang_mn">
                            <textarea @click="selectPopup('open','videoscategory')" v-model="condition1.title" readonly :placeholder="U2M('ᠲᠥᠷᠥᠯ ᠵᠦᠢᠯ')"></textarea>
                        </div>
                    </div>
                    <div class="aqt_footer">
                        <cube-button class="delete" icon="cubeic-wrong" @click="showPopup('close')"></cube-button><span>&nbsp;</span>
                        <cube-button icon='icon_zhongzhi-copy iconfont'   @click="mnpopupPopupDel"></cube-button><span>&nbsp;</span>
                        <cube-button class="but" @click="mnpopupPopupOk" :light="true" icon="cubeic-search"></cube-button>
                    </div>
                </div>
            </div>
            <div class="aqt_mnpopup_div" v-if="['/AuthorListNew'].includes($route.path)" >
                <div class="aqt_mnpopup_form">
                    <div class="aqt_cont aqt_su aqt_ce_ve" :style="{height: ['/Resourcevideo','/AuthorListNew'].includes($route.path)?'calc(100% - 9rem)':'calc(100% - 5rem)'}">
                        <div class="aqt_input titlefont aqt_lang_mn">
                            <textarea v-model.trim="condition2.name" :placeholder="U2M('ᠵᠤᠬᠢᠶᠠᠭᠴᠢ  ᠶᠢᠨ ᠨᠡᠷ᠎ᠡ')"></textarea>
                        </div>
                    </div>
                    <div class="aqt_footer">
                        <cube-button class="delete" icon="cubeic-wrong" @click="showPopup('close')"></cube-button><span>&nbsp;</span>
                        <cube-button icon='icon_zhongzhi-copy iconfont'   @click="mnpopupPopupDel"></cube-button><span>&nbsp;</span>
                        <cube-button class="but" @click="mnpopupPopupOk" :light="true" icon="cubeic-search"></cube-button>
                    </div>
                </div>
            </div>
            <div class="aqt_selectpopup_div" v-if="selectPopupIs">
                <div class="aqt_mnpopup_form">
                   <div class="aqt_cont" :style="{height: ['/Resourcevideo','/AuthorListNew'].includes($route.path)?'calc(100% - 9rem)':'calc(100% - 5rem)'}">
					    <lay-trees v-if="dataList" :dataList="dataList" @on-child-click="onChildClick"></lay-trees>
                   </div>
                    <div class="aqt_footer">
                        <cube-button class="delete" :light="true" icon="cubeic-wrong" @click="selectPopup('close')"></cube-button>
                        <cube-button class="but" :light="true" icon="cubeic-ok" @click="selectPopupOk"></cube-button>
                    </div>
                </div>
            </div>
        </div>
        <lay-confirm :IsShow="confirm.IsShow" :isAlert="true" :message="confirm.message" @isClose="confirm.IsShow=false" @on-ok="confirm.IsShow=false"></lay-confirm>
    </div>
</template>
<script>
export default {
    components: {
        LayTrees: resolve => {require(['./LayTrees.vue'], resolve);},
    },
	data() {
		return {
			confirm:{
				IsShow:false,
				message:''
            },
            mnPopupIs:false,
            selectPopupIs:false,
            listTypeIs:null,//用来判断级联
			dataList:[],
			condition:{
				name:'',//书名
				category_id:'',//书籍分类id
				category_name:'',//书籍分类名称
				culture_id:'',//文化分类id
				culture_name:'',//文化分类名称
				press_id:'',//出版社id
				press_name:'',//出版社名称
				isbn:'',//书号
				author:'',//作者名称
				start_time:'',//出版开始时间
				end_time:'',//出版结束时间
            },
			condition1:{
                title:null,
                fid:null
            },
			condition2:{
                name:null
            },
            itemcondition:{},//当前点击的数据
			startTimePicker:null,//开始时间
			endTimePicker:null,//结束日期
		}
	},
	methods:{

        /*****************************************************************
         *
         * 获取出版社
         *
         *****************************************************************/
        queryDataPress(){
			const _this = this
			_this.showToastMask(true)
            _this.$http.get(`api/web/v1/press/index`)
            .then(val =>{
				_this.dataList = val.data.map((item,index)=>{
					let arr = {
						title:item.press_name_lang.mn?item.press_name_lang.mn:item.press_name_lang.zh,
						// title:item.press_name_lang.zh,
						id:item.press_id,
						child:[]
					}
					return arr
                })
				_this.showToastMask(false)
            }).catch(err =>{
				_this.showToastMask(false)
            })
        },

        /*****************************************************************
         *
         * 获取文化类型
         *
         *****************************************************************/
        queryDataCulture(){
			const _this = this
			_this.showToastMask(true)
            _this.$http.get(`api/base/v1/web/pidFind/0`)
            .then(val =>{
				_this.dataList = val.data.map((item,index)=>{
					let arr = {
						// title:item.name_lang.zh,
						title:item.name_lang.mn?item.name_lang.mn:item.name_lang.zh,
						id:item.culture_type_id,
						child:[]
					}
					return arr
                })
				_this.showToastMask(false)
            }).catch(err =>{
				_this.showToastMask(false)
            })
        },
		loadDataCulture(data){
            const _this = this
			_this.showToastMask(true)
            _this.$http.get(`api/base/v1/web/pidFind/${data.id}`)
            .then(val =>{
				data.child = val.data.map((item,index)=>{
					let arr = {
						// title:item.name_lang.zh,
						title:item.name_lang.mn?item.name_lang.mn:item.name_lang.zh,
						id:item.culture_type_id,
						child:[]
					}
					return arr
				})
				_this.showToastMask(false)
            }).catch(err =>{
				_this.showToastMask(false)

            })
        },

        /*****************************************************************
         *
         * 获取书籍分类
         *
         *****************************************************************/
        queryDataCategory(){
			const _this = this
			_this.showToastMask(true)
            _this.$http.get(`api/web/v1/bookcategory/pidFind/0`)
            .then(val =>{
				_this.dataList = val.data.map((item,index)=>{
					let arr = {
						// title:item.name_lang.zh,
						title:item.name_lang.mn?item.name_lang.mn:item.name_lang.zh,
						id:item.book_category_id,
						child:[]
					}
					return arr
                })
				_this.showToastMask(false)
            }).catch(err =>{
				_this.showToastMask(false)
            })
        },
		loadDataCategory(data){
            const _this = this
			_this.showToastMask(true)
            _this.$http.get(`api/web/v1/bookcategory/pidFind/${data.id}`)
            .then(val =>{
				data.child = val.data.map((item,index)=>{
					let arr = {
						// title:item.name_lang.zh,
						title:item.name_lang.mn?item.name_lang.mn:item.name_lang.zh,
						id:item.book_category_id,
						child:[]
					}
					return arr
				})
				_this.showToastMask(false)
            }).catch(err =>{
				_this.showToastMask(false)

            })
        },


        /*****************************************************************
         *
         * 获取视频分类
         *
         *****************************************************************/
        queryDataVideosCategory(){
			const _this = this
			_this.showToastMask(true)
            _this.$http.get(`api/web/v1/videoscategory/indexs?type=${_this.library_num}`)
            .then(val =>{
				_this.dataList = val.data.map((item,index)=>{
					let arr = {
						title:item.titlelang.mn?item.titlelang.mn:item.titlelang.zh,
						id:item.videos_category_id,
						child:[]
					}
					return arr
                })
				_this.showToastMask(false)
            }).catch(err =>{
				_this.showToastMask(false)
            })
        },
		loadDataVideosCategory(data){
            const _this = this
			_this.showToastMask(true)
            _this.$http.get(`api/web/v1/videoscategory/indexs?type=${_this.library_num}&fid=${data.id}`)
            .then(val =>{
				data.child = val.data.map((item,index)=>{
					let arr = {
						title:item.titlelang.mn?item.titlelang.mn:item.titlelang.zh,
						id:item.videos_category_id,
						child:[]
					}
					return arr
				})
				_this.showToastMask(false)
            }).catch(err =>{
				_this.showToastMask(false)

            })
        },


        /*****************************************************************
         *
         * 弹出层显示
         *
         *****************************************************************/
		showPopup(type) {
            let _this = this
            if(type == 'open'){
                _this.mnPopupIs = true
            }
            if(type == 'close'){
                _this.mnPopupIs = false
            }
        },

        /*****************************************************************
         *
         * 选项层
         *
         *****************************************************************/
        selectPopup(type,listtype=null) {
            let _this = this
            if(type == 'open'){
                _this.itemcondition = {}
                _this.listTypeIs = listtype
                _this.dataList = []
                if(listtype == 'category'){
                    // 书籍分类
                    _this.queryDataCategory()
                }
                if(listtype == 'culture'){
                    // 文化类型
                    _this.queryDataCulture()
                }
                if(listtype == 'press'){
                    // 出版社
                    _this.queryDataPress()
                }
                if(listtype == 'videoscategory'){
                    // 视频分类
                    _this.queryDataVideosCategory()
                }
                _this.selectPopupIs = true
            }
            if(type == 'close'){
                _this.selectPopupIs = false
            }
        },

        /*****************************************************************
         *
         * 点击下一级
         *
         *****************************************************************/
		onChildClick(item,index){
            const _this = this
            _this.itemcondition = item
            if(_this.listTypeIs == 'category'){
                _this.loadDataCategory(item)
            }
            if(_this.listTypeIs == 'culture'){
                _this.loadDataCulture(item)
            }
            if(_this.listTypeIs == 'press'){

            }
            if(_this.listTypeIs == 'videoscategory'){
                // 视频分类
                _this.loadDataVideosCategory(item)
            }


		},

        /*****************************************************************
         *
         * 点击选项层的确定
         *
         *****************************************************************/
		selectPopupOk(){
			const _this = this
            if(_this.listTypeIs == 'category'){
                _this.condition.category_id = _this.itemcondition.id
                _this.condition.category_name = _this.itemcondition.title
            }
            if(_this.listTypeIs == 'culture'){
                _this.condition.culture_id = _this.itemcondition.id
                _this.condition.culture_name = _this.itemcondition.title
            }
            if(_this.listTypeIs == 'press'){
                _this.condition.press_id = _this.itemcondition.id
                _this.condition.press_name = _this.itemcondition.title
            }
            if(_this.listTypeIs == 'videoscategory'){
                _this.condition1.fid = _this.itemcondition.id
                _this.condition1.title = _this.itemcondition.title
            }
            _this.selectPopupIs = false
        },

        /*****************************************************************
         *
         * 开始时间
         *
         *****************************************************************/
		showStartTimePicker() {
            let _this = this
			if (!_this.startTimePicker) {
				_this.startTimePicker = _this.$createDatePicker({
					title: 'Start Year',
                    startColumn:'year',
                    min: new Date(1600, 0, 12),
                    value:new Date(),
                    columnCount:2,
					onSelect: _this.selectStartHandle,
					onCancel: _this.cancelStartHandle
				})
			}
			_this.startTimePicker.show()
		},
		selectStartHandle(date,selectedVal,selectedText){
            let _this = this
            _this.condition.start_time = selectedVal[0]
		},
		cancelStartHandle(){
            let _this = this
            _this.condition.start_time = ''
        },

        /*****************************************************************
         *
         * 结束时间
         *
         *****************************************************************/
		showEndTimePicker() {
            let _this = this
			if (!_this.endTimePicker) {
				_this.endTimePicker = _this.$createDatePicker({
					title: 'End Year',
                    startColumn:'year',
                    min: new Date(1600, 0, 12),
                    value:new Date(),
                    columnCount:2,
					onSelect: _this.selectEndHandle,
					onCancel: _this.cancelEndHandle
				})
			}
			_this.endTimePicker.show()
		},
		selectEndHandle(date,selectedVal,selectedText){
            let _this = this
            _this.condition.end_time = selectedVal[0]
		},
		cancelEndHandle(){
            let _this = this
            _this.condition.end_time = ''
        },

        /*****************************************************************
         *
         * 点击搜索
         *
         *****************************************************************/
        mnpopupPopupOk(){
            let _this = this
            if(_this.condition.start_time){
                if(!_this.condition.end_time){
                    _this.confirm.message = '请选择结束时间!'
                    _this.confirm.IsShow = true
                    return
                }else{
                    if(_this.condition.start_time > _this.condition.end_time){
                        _this.confirm.message = '开始时间必须大于结束时间!'
                        _this.confirm.IsShow = true
                        return
                    }
                }
            }else{
                if(_this.condition.end_time){
                    if(!_this.condition.start_time){
                        _this.confirm.message = '请选择开始时间!'
                        _this.confirm.IsShow = true
                        return
                    }else{
                        if(_this.condition.start_time > _this.condition.end_time){
                            _this.confirm.message = '开始时间必须大于结束时间!'
                            _this.confirm.IsShow = true
                            return
                        }
                    }
                }
            }
            if(_this.$route.path == '/Resourcevideo'){
                _this.$emit('on-search-click',_this.condition1);
            }else if(_this.$route.path == '/AuthorListNew'){
                _this.$emit('on-search-click',_this.condition2);
            }else{
                _this.$emit('on-search-click',_this.condition);
            }
            _this.mnPopupIs = false
            // _this.mnpopupPopupDel()
        },

        /*****************************************************************
         *
         * 点击重置
         *
         *****************************************************************/
        mnpopupPopupDel(){
			this.condition = {
				name:'',//书名
				category_id:'',//书籍分类id
				category_name:'',//书籍分类名称
				culture_id:'',//文化分类id
				culture_name:'',//文化分类名称
				press_id:'',//出版社id
				press_name:'',//出版社名称
				isbn:'',//书号
				author:'',//作者名称
				start_time:'',//出版开始时间
				end_time:'',//出版结束时间
            }
			this.condition1 = {
				title:'',
				fid:null,
            }
			this.condition2 = {
				name:'',
            }
        }
	},
	mounted(){
    },
    computed: {
        library_num() {
            let vv = JSON.parse(localStorage.getItem("theme_color")) ? JSON.parse(localStorage.getItem("theme_color")).library_num : null;
            return vv;
        },
    },
    watch: {
        "$route.path": {
            // 表示对象中属性变化的处理函数，这个函数只能叫这个名字
            handler(newVal) {
                this.showPopup('close')
            },
            immediate: false,
            deep: true, // 表示开启深度监听
        },
    },
}
</script>
<style lang="less">
#aqt_image_search_button{
    // width: 4.3rem;
    // height: 96%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    // margin: 2% 0.7rem;
    // background-color: var(--themeColor);
    // color: #ffffff;
    // border-radius: .5rem;
    // font-size: 2rem;
    .aqt_mnpopup{
        .aqt_selectpopup_div,.aqt_mnpopup_div{
            position: fixed;
            top: 5rem;
            bottom: 0rem;
            right: 0;
            left: 0;
            background-color: rgba(0,0,0,.4);
            z-index: 4;
            .aqt_mnpopup_form{
                background-color: #EDEDED;
                height: 100%;
                padding-right: 0.4rem;
                .aqt_input{
                    width: 4rem;
                    height: 100%;
                    margin-left: .8rem;
                    textarea{
                        width: 100%;
                        height: 100%;
                        display: table-column!important;
                        color: #000000;
                        text-indent: 1rem;
                        border-radius: .5rem;
                        resize: none;
                        border: none;
                        outline: none;
                        vertical-align: middle;
                        overflow-x: hidden!important;
                        white-space:nowrap;
                        line-height: 4.3rem;
                    }
                    textarea.aqt_time{
                        height: 48%;
                    }
                    // textarea::-webkit-input-placeholder{
                    //     font-size: 1.6rem;
                    // }
                    // textarea::-moz-placeholder{   /* Mozilla Firefox 19+ */
                    //     font-size: 1.6rem;
                    // }
                    // textarea:-moz-placeholder{    /* Mozilla Firefox 4 to 18 */
                    //     font-size: 1.6rem;
                    // }
                    // textarea:-ms-input-placeholder{  /* Internet Explorer 10-11 */
                    //     font-size: 1.6rem;
                    // }
                }
                .aqt_icon{
                    width: 98%;
                    height: 4rem;
                    margin: 0 auto;
                    .cubeic-wrong:before{
                        font-size: 3rem;
                        color: red;
                    }

                }

                .aqt_cont{
                    width: 100%;
                    height: calc(100% - 9rem);
                    overflow-x: scroll;
                }
                .aqt_footer{
                    width: 98%;
                    height: 4rem;
                    margin: 0.5rem auto;
                    display: flex;
                    .but{
                        background-color: var(--themeColor);
                    }
                    .delete{
                        background-color: red;

                    }
                    button{
                        padding: 9px 10px;
                        .cubeic-ok:before,.cubeic-search:before,.cubeic-wrong:before{
                            font-size: 2rem;
                            color: #ffffff;
                        }
                        img{
                            width: 1.6rem;
                            height: 1.6rem;
                        }
                    }
                }

            }
        }
    }

}
</style>
